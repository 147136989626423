<template>
  <section class="internetServices">
    <imageBanner :imgBannerObj="imgBannerObj" />
    <div class="container">
      <div class="main">
        <p class="title">{{ options.title }}</p>
        <p class="text">{{ options.text }}</p>
        <div class="links">
          <el-image :src="options.imgUrl"></el-image>
          <router-link v-for="(item, index) in options.links" :key="index" :class="'link link' + index" :to="{path: item.url, query: {id: item.id}}">
            <el-image :src="item.imgUrl" style="width: 100%; height: 100%" fit="cover"></el-image>
            <span class="link-title">{{ item.title }}</span>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import imageBanner from '../../components/ImageBanner.vue'
export default {
  components: {imageBanner,},
  data () {
    return {
      imgBannerObj: {
        url: require('../../assets/images/aboutus/互联网服务.png')
      },
      options: {
        title: 'INTERNET SERVICE',
        text: '做性价比最高的商用一体化管理系统',
        imgUrl: require('../../assets/images/2023/investor/首页_底图.png'),
        links: [
          {url: '/internet', id: 1, imgUrl: require('../../assets/images/2023/investor/首页_1.png'), title: '目标和愿景'},
          {url: '/internet', id: 2, imgUrl: require('../../assets/images/2023/investor/首页_2.png'), title: '服务内容'},
          {url: '/information', id: 4, imgUrl: require('../../assets/images/2023/investor/首页_3.png'), title: '未来"一体化"'},
        ]
      }
    }
  }
}
</script>

<style scoped>
.internetServices {background-color: #ecf0f5; padding-bottom: 1rem;}
.main {text-align: center; line-height: 2;}
.main .title {font-size: 3rem; color: rgba(230, 119, 0, .5);}
.main .text {color: #666; font-size: 1.4rem;}
.links {position: relative;}
.link {position: absolute; z-index: 1; border-radius: 50%; overflow: hidden; width: 15%;}
.link .link-title {position: absolute; z-index: 2; left: 50%; top: 20%; transform: translate(-50%, -50%); color: #fff;}
.link:hover {transform: scale(1.2, 1.2)}
.link0 {left: 5%; top: 16%}
.link1 {left: 50%; top: 24%}
.link2 {left: 62%; top: 74%}
.links .el-image, .links /deep/ img {padding: 0; aspect-ratio: 1; object-fit: cover;}
</style>